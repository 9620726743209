
import { Component, Emit, Model, Prop, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import { mask } from "vue-the-mask";
import moment from "moment";

import { Reservation } from "../store";

@Component({
  components: {
    datepicker_: () => import("@/app/modules/service/builder/elements/DatePicker.vue"),
    timepicker_: () => import("@/app/modules/service/builder/elements/TimePicker.vue"),
    input_: () => import("@/app/modules/service/builder/elements/Input.vue")
  },
  directives: {
    mask
  }
})
export default class ReservationEditForm extends Vue {
  @Prop(Boolean) readonly disabled?: boolean;
  @Model("input", { type: Object }) readonly formData!: Reservation;

  @Emit()
  input() {
    return this.localFormData;
  }

  @Watch("localFormData", { deep: true })
  onLocalFormDataChanged() {
    this.input();
  }

  localFormData!: Reservation;

  dateMin = moment().format("YYYY-MM-DD");
  valid: boolean = true as boolean;

  rules = {
    required: Rules.required
  };

  constructor() {
    super();

    this.localFormData = { ...this.formData };
  }

  allowedDates(value: string) {
    return this.formData.resource.days.includes(moment(value).day());
  }
}
